import classNames from 'classnames';
import Scrim from './Scrim';
import FocusTrap from 'focus-trap-react';
import closeIcon from '@/icons/close-icon.svg';
import useScrollCapture from '@/hooks/use-scroll-capture';

/**
 * Preact modal component.
 * Modal Controller is required to open and close the modal.
 * Children are rendered inside the modal.
 */
export default function Modal({children, controller}: ModalProps) {
  const isOpen = controller.isOpen;
  useScrollCapture(isOpen);

  return (
    <>
      <Scrim showScrim={isOpen} onClick={() => controller.close()} />
      <FocusTrap
        active={isOpen}
        focusTrapOptions={{
          clickOutsideDeactivates: true,
          escapeDeactivates: true,
          onPostDeactivate: () => controller.close(),
        }}
      >
        <div
          className={classNames(
            'size-md:h-[unset] size-md:max-h-[90vh] size-md:w-[unset] size-md:max-w-[90vw] size-md:rounded-2xl size-md:p-16 fixed left-1/2 top-1/2 z-50 flex h-full w-full -translate-x-1/2 -translate-y-1/2 justify-center bg-crisp-white pb-6 pt-12 shadow-2xl transition-all duration-300 ease-in-out',
            {
              'pointer-events-none scale-75 opacity-0': !isOpen,
              'opacity-1  scale-100': isOpen,
            }
          )}
        >
          <button
            onClick={() => controller.close()}
            className="absolute right-0 top-0"
          >
            <img src={closeIcon} alt="close drawer icon" />
          </button>
          <div className="max-h-full overflow-auto px-6">{children}</div>
        </div>
      </FocusTrap>
    </>
  );
}

export type ModalProps = {
  children: string | JSX.Element;
  controller: ModalController;
};

/**
 * The modal controller interface
 * @param isOpen - if the modal is open
 * @param close - method to close the modal
 * @see Modal
 */
export type ModalController = {
  isOpen: boolean;
  close: () => void;
};
